/**
 * @license
 * Generated by Zeplin
 * Copyright (C) 2021 - present Zeplin
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at

 *     http://www.apache.org/licenses/LICENSE-2.0

 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

@font-face {
    font-family: IBMPlexSans;
    src: local(IBMPlexSans-SemiBold), url(/assets/fonts/IBMPlexSans-SemiBold.ttf) format('truetype');
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: IBMPlexSans;
    src: local(IBMPlexSans-Bold), url(/assets/fonts/IBMPlexSans-Bold.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: OpenSans;
    src: local(OpenSans), url(/assets/fonts/OpenSans-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: OpenSans;
    src: local(OpenSans-Regular), url(/assets/fonts/OpenSans-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: OpenSans;
    src: local(OpenSans-SemiBold), url(/assets/fonts/OpenSans-SemiBold.ttf) format('truetype');
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: OpenSans;
    src: local(OpenSans-Bold), url(/assets/fonts/OpenSans-Bold.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: OpenSans;
    src: local(OpenSans-ExtraBold), url(/assets/fonts/OpenSans-ExtraBold.ttf) format('truetype');
    font-weight: 800;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: SFProDisplay;
    src: local(SFProDisplay-Bold), url(/assets/fonts/SFProDisplay-Bold.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: SFProText;
    src: local(SFProText-Regular), url(/assets/fonts/SFProText-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: SFProText;
    src: local(SFProText-Medium), url(/assets/fonts/SFProText-Medium.ttf) format('truetype');
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: SFProText;
    src: local(SFProText-Semibold), url(/assets/fonts/SFProText-Semibold.ttf) format('truetype');
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: SFProText;
    src: local(SFProText-Bold), url(/assets/fonts/SFProText-Bold.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: SFProText;
    src: local(SFProText-Heavy), url(/assets/fonts/SFProText-Heavy.ttf) format('truetype');
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
}

.Heading1SemiBoldDefaultLeftJ {
    font-family: IBMPlexSans, sans-serif;
    font-size: 36px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: -0.86px;
    color: #1b1a1a;
}

.H1YellowLeft {
    font-family: IBMPlexSans, sans-serif;
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -1px;
    color: var(--high-bg);
}

.H1YellowCenter {
    font-family: IBMPlexSans, sans-serif;
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -1px;
    text-align: center;
    color: var(--high-bg);
}

.H1WhiteLeft {
    font-family: IBMPlexSans, sans-serif;
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -1px;
    color: var(--white);
}

.H1WhiteCenter {
    font-family: IBMPlexSans, sans-serif;
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -1px;
    text-align: center;
    color: var(--white);
}

.H1RightYellow {
    font-family: IBMPlexSans, sans-serif;
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -1px;
    text-align: center;
    color: #ffa700;
}

.H1RightWhite {
    font-family: IBMPlexSans, sans-serif;
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -1px;
    text-align: center;
    color: var(--white);
}

.H1RightRed {
    font-family: IBMPlexSans, sans-serif;
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -1px;
    text-align: center;
    color: #f75e4c;
}

.H1RightLightGray {
    font-family: IBMPlexSans, sans-serif;
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -1px;
    text-align: center;
    color: #8d8d93;
}

.H1RightGray {
    font-family: IBMPlexSans, sans-serif;
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -1px;
    text-align: center;
    color: #656565;
}

.H1RightBlack {
    font-family: IBMPlexSans, sans-serif;
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -1px;
    text-align: center;
    color: var(--black);
}

.H1RedLeft {
    font-family: IBMPlexSans, sans-serif;
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -1px;
    color: var(--low-bg);
}

.H1RedCenter {
    font-family: IBMPlexSans, sans-serif;
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -1px;
    text-align: center;
    color: var(--low-bg);
}

.H1PurpleLeft {
    font-family: IBMPlexSans, sans-serif;
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -1px;
    color: var(--auto-purple);
}

.H1PurpleCenter {
    font-family: IBMPlexSans, sans-serif;
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -1px;
    text-align: center;
    color: var(--auto-purple);
}

.H1Light-GreyLeft {
    font-family: IBMPlexSans, sans-serif;
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -1px;
    color: var(--disabled);
}

.H1Light-GreyCenter {
    font-family: IBMPlexSans, sans-serif;
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -1px;
    text-align: center;
    color: var(--disabled);
}

.H1LeftYellow {
    font-family: IBMPlexSans, sans-serif;
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -1px;
    color: #ffa700;
}

.H1LeftWhite {
    font-family: IBMPlexSans, sans-serif;
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -1px;
    color: var(--white);
}

.H1LeftRed {
    font-family: IBMPlexSans, sans-serif;
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -1px;
    color: #f75e4c;
}

.H1LeftPurple {
    font-family: IBMPlexSans, sans-serif;
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -1px;
    color: #743dbc;
}

.H1LeftLightGray {
    font-family: IBMPlexSans, sans-serif;
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -1px;
    color: #8d8d93;
}

.H1LeftGray {
    font-family: IBMPlexSans, sans-serif;
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -1px;
    color: #656565;
}

.H1LeftBlack {
    font-family: IBMPlexSans, sans-serif;
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -1px;
    color: var(--black);
}

.H1GreenLeft {
    font-family: IBMPlexSans, sans-serif;
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -1px;
    color: var(--hypo-protect);
}

.H1GreenCenter {
    font-family: IBMPlexSans, sans-serif;
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -1px;
    text-align: center;
    color: var(--hypo-protect);
}

.H1Dark-GreyLeft {
    font-family: IBMPlexSans, sans-serif;
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -1px;
    color: var(--dark-grey);
}

.H1Dark-GreyCenter {
    font-family: IBMPlexSans, sans-serif;
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -1px;
    text-align: center;
    color: var(--dark-grey);
}

.H1CenterYellow {
    font-family: IBMPlexSans, sans-serif;
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -1px;
    text-align: center;
    color: #ffa700;
}

.H1CenterWhite {
    font-family: IBMPlexSans, sans-serif;
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -1px;
    text-align: center;
    color: var(--white);
}

.H1CenterRed {
    font-family: IBMPlexSans, sans-serif;
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -1px;
    text-align: center;
    color: #f75e4c;
}

.H1CenterPurple {
    font-family: IBMPlexSans, sans-serif;
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -1px;
    text-align: center;
    color: #743dbc;
}

.H1CenterLightGray {
    font-family: IBMPlexSans, sans-serif;
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -1px;
    text-align: center;
    color: #8d8d93;
}

.H1CenterGray {
    font-family: IBMPlexSans, sans-serif;
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -1px;
    text-align: center;
    color: #656565;
}

.H1CenterBlack {
    font-family: IBMPlexSans, sans-serif;
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -1px;
    text-align: center;
    color: var(--black);
}

.H1BlueLeft {
    font-family: IBMPlexSans, sans-serif;
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -1px;
    color: var(--manual-blue);
}

.H1BlueCenter {
    font-family: IBMPlexSans, sans-serif;
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -1px;
    text-align: center;
    color: var(--manual-blue);
}

.H1BlackLeft {
    font-family: IBMPlexSans, sans-serif;
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -1px;
    color: var(--black);
}

.H1BlackCenter {
    font-family: IBMPlexSans, sans-serif;
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -1px;
    text-align: center;
    color: var(--black);
}

.H1-CopyRightPurple {
    font-family: IBMPlexSans, sans-serif;
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -1px;
    text-align: center;
    color: #743dbc;
}

.H2RightYellow {
    font-family: SFProText, sans-serif;
    font-size: 24px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.86px;
    text-align: center;
    color: #ffa700;
}

.H2RightWhite {
    font-family: SFProText, sans-serif;
    font-size: 24px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.86px;
    text-align: center;
    color: var(--white);
}

.H2RightRed {
    font-family: SFProText, sans-serif;
    font-size: 24px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.86px;
    text-align: center;
    color: #f75e4c;
}

.H2RightPurple {
    font-family: SFProText, sans-serif;
    font-size: 24px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.86px;
    text-align: center;
    color: #743dbc;
}

.H2RightLightGray {
    font-family: SFProText, sans-serif;
    font-size: 24px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.86px;
    text-align: center;
    color: #8d8d93;
}

.H2RightGray {
    font-family: SFProText, sans-serif;
    font-size: 24px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.86px;
    text-align: center;
    color: #656565;
}

.H2RightBlack {
    font-family: SFProText, sans-serif;
    font-size: 24px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.86px;
    text-align: center;
    color: var(--black);
}

.H2LeftYellow {
    font-family: SFProText, sans-serif;
    font-size: 24px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.86px;
    color: #ffa700;
}

.H2LeftWhite {
    font-family: SFProText, sans-serif;
    font-size: 24px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.86px;
    color: var(--white);
}

.H2LeftRed {
    font-family: SFProText, sans-serif;
    font-size: 24px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.86px;
    color: #f75e4c;
}

.H2LeftPurple {
    font-family: SFProText, sans-serif;
    font-size: 24px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.86px;
    color: #743dbc;
}

.H2LeftLightGray {
    font-family: SFProText, sans-serif;
    font-size: 24px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.86px;
    color: #8d8d93;
}

.H2LeftGray {
    font-family: SFProText, sans-serif;
    font-size: 24px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.86px;
    color: #656565;
}

.H2LeftBlack {
    font-family: SFProText, sans-serif;
    font-size: 24px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.86px;
    color: var(--black);
}

.H2CenterYellow {
    font-family: SFProText, sans-serif;
    font-size: 24px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.86px;
    text-align: center;
    color: #ffa700;
}

.H2CenterWhite {
    font-family: SFProText, sans-serif;
    font-size: 24px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.86px;
    text-align: center;
    color: var(--white);
}

.H2CenterRed {
    font-family: SFProText, sans-serif;
    font-size: 24px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.86px;
    text-align: center;
    color: #f75e4c;
}

.H2CenterPurple {
    font-family: SFProText, sans-serif;
    font-size: 24px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.86px;
    text-align: center;
    color: #743dbc;
}

.H2CenterLIghtGray {
    font-family: SFProText, sans-serif;
    font-size: 24px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.86px;
    text-align: center;
    color: #8d8d93;
}

.H2CenterGray {
    font-family: SFProText, sans-serif;
    font-size: 24px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.86px;
    text-align: center;
    color: #656565;
}

.H2CenterBlack {
    font-family: SFProText, sans-serif;
    font-size: 24px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.86px;
    text-align: center;
    color: var(--black);
}

.H2YellowLeft {
    font-family: IBMPlexSans, sans-serif;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.86px;
    color: var(--high-bg);
}

.H2YellowCenter {
    font-family: IBMPlexSans, sans-serif;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.86px;
    text-align: center;
    color: var(--high-bg);
}

.H2WhiteLeft {
    font-family: IBMPlexSans, sans-serif;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.86px;
    color: var(--white);
}

.H2WhiteCenter {
    font-family: IBMPlexSans, sans-serif;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.86px;
    text-align: center;
    color: var(--white);
}

.H2RedLeft {
    font-family: IBMPlexSans, sans-serif;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.86px;
    color: var(--low-bg);
}

.H2RedCenter {
    font-family: IBMPlexSans, sans-serif;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.86px;
    text-align: center;
    color: var(--low-bg);
}

.H2PurpleLeft {
    font-family: IBMPlexSans, sans-serif;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.86px;
    color: var(--auto-purple);
}

.H2PurpleCenter {
    font-family: IBMPlexSans, sans-serif;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.86px;
    text-align: center;
    color: var(--auto-purple);
}

.H2Light-GreyLeft {
    font-family: IBMPlexSans, sans-serif;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.86px;
    color: var(--disabled);
}

.H2Light-GreyCenter {
    font-family: IBMPlexSans, sans-serif;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.86px;
    text-align: center;
    color: var(--disabled);
}

.H2GreenLeft {
    font-family: IBMPlexSans, sans-serif;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.86px;
    color: var(--hypo-protect);
}

.H2GreenCenter {
    font-family: IBMPlexSans, sans-serif;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.86px;
    text-align: center;
    color: var(--hypo-protect);
}

.H2Dark-GreyLeft {
    font-family: IBMPlexSans, sans-serif;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.86px;
    color: var(--dark-grey);
}

.H2Dark-GreyCenter {
    font-family: IBMPlexSans, sans-serif;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.86px;
    text-align: center;
    color: var(--dark-grey);
}

.H2BlueLeft {
    font-family: IBMPlexSans, sans-serif;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.86px;
    color: var(--manual-blue);
}

.H2BlueCenter {
    font-family: IBMPlexSans, sans-serif;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.86px;
    text-align: center;
    color: var(--manual-blue);
}

.H2BlackLeft {
    font-family: IBMPlexSans, sans-serif;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.86px;
    color: var(--black);
}

.H2BlackCenter {
    font-family: IBMPlexSans, sans-serif;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.86px;
    text-align: center;
    color: var(--black);
}

.Title22-Bold1-Light1-Label-Color1-Left-Aligned {
    font-family: SFProDisplay, sans-serif;
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.27;
    letter-spacing: 0.35px;
    color: var(--black);
}

.H3RightYellow {
    font-family: SFProText, sans-serif;
    font-size: 20px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.71px;
    text-align: center;
    color: #ffa700;
}

.H3RightWhite {
    font-family: SFProText, sans-serif;
    font-size: 20px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.71px;
    text-align: center;
    color: var(--white);
}

.H3RightRed {
    font-family: SFProText, sans-serif;
    font-size: 20px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.71px;
    text-align: center;
    color: #f75e4c;
}

.H3RightPurple {
    font-family: SFProText, sans-serif;
    font-size: 20px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.71px;
    text-align: center;
    color: #743dbc;
}

.H3RightLightGray {
    font-family: SFProText, sans-serif;
    font-size: 20px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.71px;
    text-align: center;
    color: #8d8d93;
}

.H3RightGray {
    font-family: SFProText, sans-serif;
    font-size: 20px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.71px;
    text-align: center;
    color: #656565;
}

.H3RightBlack {
    font-family: SFProText, sans-serif;
    font-size: 20px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.71px;
    text-align: center;
    color: var(--black);
}

.H3LeftYellow {
    font-family: SFProText, sans-serif;
    font-size: 20px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.71px;
    color: #ffa700;
}

.H3LeftWhite {
    font-family: SFProText, sans-serif;
    font-size: 20px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.71px;
    color: var(--white);
}

.H3LeftRed {
    font-family: SFProText, sans-serif;
    font-size: 20px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.71px;
    color: #f75e4c;
}

.H3LeftPurple {
    font-family: SFProText, sans-serif;
    font-size: 20px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.71px;
    color: #743dbc;
}

.H3LeftGray-2 {
    font-family: SFProText, sans-serif;
    font-size: 20px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.71px;
    color: #8d8d93;
}

.H3LeftGray {
    font-family: SFProText, sans-serif;
    font-size: 20px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.71px;
    color: #656565;
}

.H3LeftBlack {
    font-family: SFProText, sans-serif;
    font-size: 20px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.71px;
    color: var(--black);
}

.H3CenterYellow {
    font-family: SFProText, sans-serif;
    font-size: 20px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.71px;
    text-align: center;
    color: #ffa700;
}

.H3CenterWhite {
    font-family: SFProText, sans-serif;
    font-size: 20px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.71px;
    text-align: center;
    color: var(--white);
}

.H3CenterRed {
    font-family: SFProText, sans-serif;
    font-size: 20px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.71px;
    text-align: center;
    color: #f75e4c;
}

.H3CenterPurple {
    font-family: SFProText, sans-serif;
    font-size: 20px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.71px;
    text-align: center;
    color: #743dbc;
}

.H3CenterLightGray {
    font-family: SFProText, sans-serif;
    font-size: 20px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.71px;
    text-align: center;
    color: #8d8d93;
}

.H3CenterGray {
    font-family: SFProText, sans-serif;
    font-size: 20px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.71px;
    text-align: center;
    color: #656565;
}

.H3CenterBlack {
    font-family: SFProText, sans-serif;
    font-size: 20px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.71px;
    text-align: center;
    color: var(--black);
}

.H3YellowLeft {
    font-family: IBMPlexSans, sans-serif;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.71px;
    color: var(--high-bg);
}

.H3YellowCenter {
    font-family: IBMPlexSans, sans-serif;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.71px;
    text-align: center;
    color: var(--high-bg);
}

.H3WhiteLeft {
    font-family: IBMPlexSans, sans-serif;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.71px;
    color: var(--white);
}

.H3WhiteCenter {
    font-family: IBMPlexSans, sans-serif;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.71px;
    text-align: center;
    color: var(--white);
}

.H3RedLeft {
    font-family: IBMPlexSans, sans-serif;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.71px;
    color: var(--low-bg);
}

.H3RedCenter {
    font-family: IBMPlexSans, sans-serif;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.71px;
    text-align: center;
    color: var(--low-bg);
}

.H3PurpleLeft {
    font-family: IBMPlexSans, sans-serif;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.71px;
    color: var(--auto-purple);
}

.H3PurpleCenter {
    font-family: IBMPlexSans, sans-serif;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.71px;
    text-align: center;
    color: var(--auto-purple);
}

.H3Light-GreyLeft {
    font-family: IBMPlexSans, sans-serif;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.71px;
    color: var(--disabled);
}

.H3Light-GreyCenter {
    font-family: IBMPlexSans, sans-serif;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.71px;
    text-align: center;
    color: var(--disabled);
}

.H3GreenLeft {
    font-family: IBMPlexSans, sans-serif;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.71px;
    color: var(--hypo-protect);
}

.H3GreenCenter {
    font-family: IBMPlexSans, sans-serif;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.71px;
    text-align: center;
    color: var(--hypo-protect);
}

.H3Dark-GreyLeft {
    font-family: IBMPlexSans, sans-serif;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.71px;
    color: var(--dark-grey);
}

.H3Dark-GreyCenter {
    font-family: IBMPlexSans, sans-serif;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.71px;
    text-align: center;
    color: var(--dark-grey);
}

.H3BlueLeft {
    font-family: IBMPlexSans, sans-serif;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.71px;
    color: var(--manual-blue);
}

.H3BlueCenter {
    font-family: IBMPlexSans, sans-serif;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.71px;
    text-align: center;
    color: var(--manual-blue);
}

.H3BlackLeft {
    font-family: IBMPlexSans, sans-serif;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.71px;
    color: var(--black);
}

.H3BlackCenter {
    font-family: IBMPlexSans, sans-serif;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.71px;
    text-align: center;
    color: var(--black);
}

.BodyYellowLeft {
    font-family: OpenSans, sans-serif;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.43px;
    color: var(--high-bg);
}

.BodyYellowCenter {
    font-family: OpenSans, sans-serif;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.43px;
    text-align: center;
    color: var(--high-bg);
}

.BodyWhiteLeft {
    font-family: OpenSans, sans-serif;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.43px;
    color: var(--white);
}

.BodyWhiteCenter {
    font-family: OpenSans, sans-serif;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.43px;
    text-align: center;
    color: var(--white);
}

.BodyRedLeft {
    font-family: OpenSans, sans-serif;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.43px;
    color: var(--low-bg);
}

.BodyRedCenter {
    font-family: OpenSans, sans-serif;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.43px;
    text-align: center;
    color: var(--low-bg);
}

.BodyPurpleLeft {
    font-family: OpenSans, sans-serif;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.43px;
    color: var(--auto-purple);
}

.BodyPurpleCenter {
    font-family: OpenSans, sans-serif;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.43px;
    text-align: center;
    color: var(--auto-purple);
}

.BodyLight-GreyLeft {
    font-family: OpenSans, sans-serif;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.43px;
    color: var(--disabled);
}

.BodyLight-GreyCenter {
    font-family: OpenSans, sans-serif;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.43px;
    text-align: center;
    color: var(--disabled);
}

.BodyGreenLeft {
    font-family: OpenSans, sans-serif;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.43px;
    color: var(--hypo-protect);
}

.BodyGreenCenter {
    font-family: OpenSans, sans-serif;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.43px;
    text-align: center;
    color: var(--hypo-protect);
}

.BodyDark-GreyLeft {
    font-family: OpenSans, sans-serif;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.43px;
    color: var(--dark-grey);
}

.BodyDark-GreyCenter {
    font-family: OpenSans, sans-serif;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.43px;
    text-align: center;
    color: var(--dark-grey);
}

.BodyBlueLeft {
    font-family: OpenSans, sans-serif;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.43px;
    color: var(--manual-blue);
}

.BodyBlueCenter {
    font-family: OpenSans, sans-serif;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.43px;
    text-align: center;
    color: var(--manual-blue);
}

.BodyBlackLeft {
    font-family: OpenSans, sans-serif;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.43px;
    color: var(--black);
}

.BodyBlackCenter {
    font-family: OpenSans, sans-serif;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.43px;
    text-align: center;
    color: var(--black);
}

.BodyRightYellow {
    font-family: SFProText, sans-serif;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.43px;
    text-align: right;
    color: #ffa700;
}

.BodyRightWhite {
    font-family: SFProText, sans-serif;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.43px;
    text-align: right;
    color: var(--white);
}

.BodyRightRed {
    font-family: SFProText, sans-serif;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.43px;
    text-align: right;
    color: #f75e4c;
}

.BodyRightPurple {
    font-family: SFProText, sans-serif;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.43px;
    text-align: right;
    color: #743dbc;
}

.BodyRightLightGray {
    font-family: SFProText, sans-serif;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.43px;
    text-align: right;
    color: #8d8d93;
}

.BodyRightGray-2 {
    font-family: SFProText, sans-serif;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.43px;
    color: #656565;
}

.BodyRightGray {
    font-family: SFProText, sans-serif;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.43px;
    text-align: right;
    color: #656565;
}

.BodyRightBlack {
    font-family: SFProText, sans-serif;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.43px;
    color: var(--black);
}

.BodyLeftYellow {
    font-family: SFProText, sans-serif;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.43px;
    color: #ffa700;
}

.BodyLeftWhite {
    font-family: SFProText, sans-serif;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.43px;
    color: var(--white);
}

.BodyLeftRed {
    font-family: SFProText, sans-serif;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.43px;
    color: #f75e4c;
}

.BodyLeftPurple {
    font-family: SFProText, sans-serif;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.43px;
    color: #743dbc;
}

.BodyLeftLightGray {
    font-family: SFProText, sans-serif;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.43px;
    color: #8d8d93;
}

.BodyLeftGray-2 {
    font-family: SFProText, sans-serif;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.43px;
    color: #656565;
}

.BodyLeftGray {
    font-family: SFProText, sans-serif;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.43px;
    color: #656565;
}

.BodyLeftBlack {
    font-family: SFProText, sans-serif;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.43px;
    color: var(--black);
}

.BodyCenterYellow {
    font-family: SFProText, sans-serif;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.43px;
    text-align: center;
    color: #ffa700;
}

.BodyCenterWhite {
    font-family: SFProText, sans-serif;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.43px;
    text-align: center;
    color: var(--white);
}

.BodyCenterRed {
    font-family: SFProText, sans-serif;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.43px;
    text-align: center;
    color: #f75e4c;
}

.BodyCenterPurple {
    font-family: SFProText, sans-serif;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.43px;
    text-align: center;
    color: #743dbc;
}

.BodyCenterLightGray {
    font-family: SFProText, sans-serif;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.43px;
    text-align: center;
    color: #8d8d93;
}

.BodyCenterGray-2 {
    font-family: SFProText, sans-serif;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.43px;
    text-align: center;
    color: #656565;
}

.BodyCenterGray {
    font-family: SFProText, sans-serif;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.43px;
    color: #656565;
}

.BodyCenterBlack {
    font-family: SFProText, sans-serif;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.43px;
    color: var(--black);
}

.SubtextRightYellow {
    font-family: SFProText, sans-serif;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: -0.64px;
    text-align: right;
    color: #ffa700;
}

.SubtextRightWhite {
    font-family: SFProText, sans-serif;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: -0.64px;
    text-align: right;
    color: var(--white);
}

.SubtextRightRed {
    font-family: SFProText, sans-serif;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: -0.64px;
    text-align: right;
    color: #f75e4c;
}

.SubtextRightPurple {
    font-family: SFProText, sans-serif;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: -0.64px;
    text-align: right;
    color: #743dbc;
}

.SubtextRightLightGray {
    font-family: SFProText, sans-serif;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: -0.64px;
    text-align: right;
    color: #8d8d93;
}

.SubtextRightGray {
    font-family: SFProText, sans-serif;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: -0.64px;
    text-align: right;
    color: #656565;
}

.SubtextRightBlack {
    font-family: SFProText, sans-serif;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: -0.64px;
    text-align: right;
    color: var(--black);
}

.SubtextLeftYellow {
    font-family: SFProText, sans-serif;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: -0.64px;
    color: #ffa700;
}

.SubtextLeftWhite {
    font-family: SFProText, sans-serif;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: -0.64px;
    color: var(--white);
}

.SubtextLeftRed {
    font-family: SFProText, sans-serif;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: -0.64px;
    color: #f75e4c;
}

.SubtextLeftPurple {
    font-family: SFProText, sans-serif;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: -0.64px;
    color: #743dbc;
}

.SubtextLeftLightGray {
    font-family: SFProText, sans-serif;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: -0.64px;
    color: #8d8d93;
}

.SubtextLeftGray {
    font-family: SFProText, sans-serif;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: -0.64px;
    color: #656565;
}

.SubtextLeftBlack {
    font-family: SFProText, sans-serif;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: -0.64px;
    color: var(--black);
}

.SubtextCenterYellow {
    font-family: SFProText, sans-serif;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: -0.64px;
    text-align: center;
    color: #ffa700;
}

.SubtextCenterWhite {
    font-family: SFProText, sans-serif;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: -0.64px;
    text-align: center;
    color: var(--white);
}

.SubtextCenterRed {
    font-family: SFProText, sans-serif;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: -0.64px;
    text-align: center;
    color: #f75e4c;
}

.SubtextCenterPurple {
    font-family: SFProText, sans-serif;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: -0.64px;
    text-align: center;
    color: #743dbc;
}

.SubtextCenterLightGray {
    font-family: SFProText, sans-serif;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: -0.64px;
    text-align: center;
    color: #8d8d93;
}

.SubtextCenterGray {
    font-family: SFProText, sans-serif;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: -0.64px;
    text-align: center;
    color: #656565;
}

.SubtextCenterBlack {
    font-family: SFProText, sans-serif;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: -0.64px;
    text-align: center;
    color: var(--black);
}

.SubtextYellowLeft {
    font-family: OpenSans, sans-serif;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: -0.64px;
    text-align: center;
    color: var(--high-bg);
}

.SubtextYellowCenter {
    font-family: OpenSans, sans-serif;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: -0.64px;
    color: var(--high-bg);
}

.SubtextWhiteLeft {
    font-family: OpenSans, sans-serif;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: -0.64px;
    color: var(--white);
}

.SubtextWhiteCenter {
    font-family: OpenSans, sans-serif;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: -0.64px;
    text-align: center;
    color: var(--white);
}

.SubtextRedLeft {
    font-family: OpenSans, sans-serif;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: -0.64px;
    color: var(--low-bg);
}

.SubtextRedCenter {
    font-family: OpenSans, sans-serif;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: -0.64px;
    text-align: center;
    color: var(--low-bg);
}

.SubtextPurpleLeft {
    font-family: OpenSans, sans-serif;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: -0.64px;
    text-align: center;
    color: var(--auto-purple);
}

.SubtextPurpleCenter {
    font-family: OpenSans, sans-serif;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: -0.64px;
    color: var(--auto-purple);
}

.SubtextLight-GreyLeft {
    font-family: OpenSans, sans-serif;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: -0.64px;
    text-align: center;
    color: var(--disabled);
}

.SubtextLight-GreyCenter {
    font-family: OpenSans, sans-serif;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: -0.64px;
    color: var(--disabled);
}

.SubtextGreenLeft {
    font-family: OpenSans, sans-serif;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: -0.64px;
    text-align: center;
    color: var(--hypo-protect);
}

.SubtextGreenCenter {
    font-family: OpenSans, sans-serif;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: -0.64px;
    color: var(--hypo-protect);
}

.SubtextDark-GreyLeft {
    font-family: OpenSans, sans-serif;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: -0.64px;
    text-align: left;
    color: var(--dark-grey);
}

.SubtextDark-GreyCenter {
    font-family: OpenSans, sans-serif;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: -0.64px;
    color: var(--dark-grey);
}

.SubtextBlueLeft {
    font-family: OpenSans, sans-serif;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: -0.64px;
    text-align: center;
    color: var(--manual-blue);
}

.SubtextBlueCenter {
    font-family: OpenSans, sans-serif;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: -0.64px;
    color: var(--manual-blue);
}

.SubtextBlackLeft {
    font-family: OpenSans, sans-serif;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: -0.64px;
    text-align: center;
    color: var(--black);
}

.SubtextBlackCenter {
    font-family: OpenSans, sans-serif;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: -0.64px;
    color: var(--black);
}

.Body2-Bold2-Dark1-Label-Color1-Left-Aligned {
    font-family: SFProText, sans-serif;
    font-size: 17px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: -0.41px;
    color: var(--white);
}

.Body2-Bold1-Light1-Label-Color1-Left-Aligned {
    font-family: SFProText, sans-serif;
    font-size: 17px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: -0.41px;
    color: var(--black);
}

.Search-BarPlaceholder {
    font-family: SFProText, sans-serif;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: -0.41px;
    color: var(--light-grey2);
}

.Body1-Default2-Dark1-Label-Color2-Center-Aligned {
    font-family: SFProText, sans-serif;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: -0.41px;
    text-align: center;
    color: var(--white);
}

.Body1-Default1-Light2-Secondary-Label-Color1-Left-Aligned {
    font-family: SFProText, sans-serif;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: -0.41px;
    color: rgba(60, 60, 67, 0.6);
}

.Body1-Default1-Light1-Label-Color1-Left-Aligned {
    font-family: SFProText, sans-serif;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: -0.41px;
    color: var(--black);
}

.Body-17RegularLeft2-Secondary {
    font-family: SFProText, sans-serif;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: -0.41px;
    color: rgba(60, 60, 67, 0.6);
}

.BUTTONBoldDarkBGCenterJ {
    font-family: OpenSans, sans-serif;
    font-size: 16px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
}

.BodyRegularLink1RightJ {
    font-family: OpenSans, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #743dbc;
}

.BodyRegularDefault2LeftJ {
    font-family: OpenSans, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #808080;
}

.BodyRegularDefaultLeftJ {
    font-family: OpenSans, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #1b1a1a;
}

.ParagraphYellowLeft {
    font-family: OpenSans, sans-serif;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.36px;
    color: var(--high-bg);
}

.ParagraphYellowCenter {
    font-family: OpenSans, sans-serif;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.36px;
    text-align: center;
    color: var(--high-bg);
}

.ParagraphWhiteLeft {
    font-family: OpenSans, sans-serif;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.36px;
    color: var(--white);
}

.ParagraphWhiteCenter {
    font-family: OpenSans, sans-serif;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.36px;
    text-align: center;
    color: var(--white);
}

.ParagraphRedLeft {
    font-family: OpenSans, sans-serif;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.36px;
    color: var(--low-bg);
}

.ParagraphRedCenter {
    font-family: OpenSans, sans-serif;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.36px;
    text-align: center;
    color: var(--low-bg);
}

.ParagraphPurpleLeft {
    font-family: OpenSans, sans-serif;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.36px;
    color: var(--auto-purple);
}

.ParagraphPurpleCenter {
    font-family: OpenSans, sans-serif;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.36px;
    text-align: center;
    color: var(--auto-purple);
}

.ParagraphGreenLeft {
    font-family: OpenSans, sans-serif;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.36px;
    color: var(--hypo-protect);
}

.ParagraphGreenCenter {
    font-family: OpenSans, sans-serif;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.36px;
    text-align: center;
    color: var(--hypo-protect);
}

.ParagraphDark-GreyLeft {
    font-family: OpenSans, sans-serif;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.36px;
    color: var(--dark-grey);
}

.ParagraphDark-GreyCenter {
    font-family: OpenSans, sans-serif;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.36px;
    text-align: center;
    color: var(--dark-grey);
}

.ParagraphBlueLeft {
    font-family: OpenSans, sans-serif;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.36px;
    color: var(--manual-blue);
}

.ParagraphBlueCenter {
    font-family: OpenSans, sans-serif;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.36px;
    text-align: center;
    color: var(--manual-blue);
}

.ParagraphBlackLeft {
    font-family: OpenSans, sans-serif;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.36px;
    color: var(--black);
}

.ParagraphBlackCenter {
    font-family: OpenSans, sans-serif;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.36px;
    text-align: center;
    color: var(--black);
}

.Subheadline2-Bold1-Light2-Secondary-Label-Color1-Left-Aligned {
    font-family: SFProText, sans-serif;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.24px;
    color: rgba(60, 60, 67, 0.6);
}

.Subheadline2-Bold1-Light1-Label-Color2-Center-Aligned {
    font-family: SFProText, sans-serif;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.24px;
    text-align: center;
    color: var(--black);
}

.Subheadline2-Bold1-Light1-Label-Color1-Left-Aligned {
    font-family: SFProText, sans-serif;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.24px;
    color: var(--black);
}

.ParagraphRightYellow {
    font-family: SFProText, sans-serif;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.36px;
    color: #ffa700;
}

.ParagraphRightWhite {
    font-family: SFProText, sans-serif;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.36px;
    color: var(--white);
}

.ParagraphRightPurple {
    font-family: SFProText, sans-serif;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.36px;
    color: #743dbc;
}

.ParagraphRightLightGray {
    font-family: SFProText, sans-serif;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.36px;
    color: #8d8d93;
}

.ParagraphRightGray {
    font-family: SFProText, sans-serif;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.36px;
    color: #656565;
}

.ParagraphRightBlack {
    font-family: SFProText, sans-serif;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.36px;
    color: var(--black);
}

.ParagraphLeftYellow {
    font-family: SFProText, sans-serif;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.36px;
    color: #ffa700;
}

.ParagraphLeftWhite {
    font-family: SFProText, sans-serif;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.36px;
    color: var(--white);
}

.ParagraphLeftRed-2 {
    font-family: SFProText, sans-serif;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.36px;
    color: #f75e4c;
}

.ParagraphLeftRed {
    font-family: SFProText, sans-serif;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.36px;
    color: #f75e4c;
}

.ParagraphLeftPurple {
    font-family: SFProText, sans-serif;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.36px;
    color: #743dbc;
}

.ParagraphLeftLightGray {
    font-family: SFProText, sans-serif;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.36px;
    color: #8d8d93;
}

.ParagraphLeftGray {
    font-family: SFProText, sans-serif;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.36px;
    color: #656565;
}

.ParagraphLeftBlack {
    font-family: SFProText, sans-serif;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.36px;
    color: var(--black);
}

.ParagraphCenterYellow {
    font-family: SFProText, sans-serif;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.36px;
    text-align: center;
    color: #ffa700;
}

.ParagraphCenterWhite {
    font-family: SFProText, sans-serif;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.36px;
    text-align: center;
    color: var(--white);
}

.ParagraphCenterRed {
    font-family: SFProText, sans-serif;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.36px;
    text-align: center;
    color: #f75e4c;
}

.ParagraphCenterPurple {
    font-family: SFProText, sans-serif;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.36px;
    text-align: center;
    color: #743dbc;
}

.ParagraphCenterLightGray {
    font-family: SFProText, sans-serif;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.36px;
    color: #8d8d93;
}

.ParagraphCenterGray {
    font-family: SFProText, sans-serif;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.36px;
    color: #656565;
}

.ParagraphCenterBlack {
    font-family: SFProText, sans-serif;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.36px;
    text-align: center;
    color: var(--black);
}

.Subheadline1-Default1-Light2-Secondary-Label-Color1-Left-Aligned {
    font-family: SFProText, sans-serif;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.24px;
    color: rgba(60, 60, 67, 0.6);
}

.Small-TitleRightYellow {
    font-family: SFProText, sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #ffa700;
}

.Small-TitleRightWhite {
    font-family: SFProText, sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: var(--white);
}

.Small-TitleRightRed {
    font-family: SFProText, sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #f75e4c;
}

.Small-TitleRightPurple {
    font-family: SFProText, sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #743dbc;
}

.Small-TitleRightLightGray {
    font-family: SFProText, sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #8d8d93;
}

.Small-TitleRightGray {
    font-family: SFProText, sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #656565;
}

.Small-TitleRightBlack {
    font-family: SFProText, sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: var(--black);
}

.Small-TitleLeftYellow {
    font-family: SFProText, sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffa700;
}

.Small-TitleLeftWhite {
    font-family: SFProText, sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--white);
}

.Small-TitleLeftPurple {
    font-family: SFProText, sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #743dbc;
}

.Small-TitleLeftLightGray {
    font-family: SFProText, sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #8d8d93;
}

.Small-TitleLeftGray {
    font-family: SFProText, sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #656565;
}

.Small-TitleLeftBlack {
    font-family: SFProText, sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--black);
}

.Small-TitleCenterYellow {
    font-family: SFProText, sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffa700;
}

.Small-TitleCenterWhite {
    font-family: SFProText, sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: var(--white);
}

.Small-TitleCenterRed-2 {
    font-family: SFProText, sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #f75e4c;
}

.Small-TitleCenterRed {
    font-family: SFProText, sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #f75e4c;
}

.Small-TitleCenterPurple {
    font-family: SFProText, sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #743dbc;
}

.Small-TitleCenterLightGray {
    font-family: SFProText, sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #8d8d93;
}

.Small-TitleCenterGray {
    font-family: SFProText, sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #656565;
}

.Small-TitleCenterBlack {
    font-family: SFProText, sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: var(--black);
}

.Small-TitleYellowLeft {
    font-family: OpenSans, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--high-bg);
}

.Small-TitleYellowCenter {
    font-family: OpenSans, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--high-bg);
}

.Small-TitleWhiteLeft {
    font-family: OpenSans, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: var(--white);
}

.Small-TitleWhiteCenter {
    font-family: OpenSans, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--white);
}

.Small-TitleRedLeft {
    font-family: OpenSans, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--low-bg);
}

.Small-TitleRedCent {
    font-family: OpenSans, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--low-bg);
}

.Small-TitlePurpleLeft {
    font-family: OpenSans, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: var(--auto-purple);
}

.Small-TitlePurpleCenter {
    font-family: OpenSans, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--auto-purple);
}

.Small-TitleLight-GreyLeft {
    font-family: OpenSans, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--disabled);
}

.Small-TitleLight-Gr {
    font-family: OpenSans, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: var(--disabled);
}

.Small-TitleGreenLeft {
    font-family: OpenSans, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--hypo-protect);
}

.Small-TitleGreenCenter {
    font-family: OpenSans, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--hypo-protect);
}

.Small-TitleDark-GreyLeft {
    font-family: OpenSans, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--dark-grey);
}

.Small-TitleBlueLeft {
    font-family: OpenSans, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--manual-blue);
}

.Small-TitleBlueCenter {
    font-family: OpenSans, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: var(--manual-blue);
}

.Small-TitleBlackLeft {
    font-family: OpenSans, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--black);
}

.Small-TitleBlackCenter {
    font-family: OpenSans, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: var(--black);
}

.Footnote2-Bold1-Light1-Label-Color2-Center-Aligned {
    font-family: SFProText, sans-serif;
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: -0.08px;
    text-align: center;
    color: var(--black);
}

.Footnote1-Default1-Light1-Label-Color2-Center-Aligned {
    font-family: SFProText, sans-serif;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: -0.08px;
    text-align: center;
    color: var(--black);
}

.TinyYellowLeft {
    font-family: OpenSans, sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: var(--high-bg);
}

.TinyYellowCenter {
    font-family: OpenSans, sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: var(--high-bg);
}

.TinyWhiteLeft {
    font-family: OpenSans, sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: var(--white);
}

.TinyWhiteCenter {
    font-family: OpenSans, sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: var(--white);
}

.TinyRedLeft {
    font-family: OpenSans, sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: var(--low-bg);
}

.TinyRedCenter {
    font-family: OpenSans, sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: var(--low-bg);
}

.TinyPurpleLeft {
    font-family: OpenSans, sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: var(--auto-purple);
}

.TinyPurpleCenter {
    font-family: OpenSans, sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: var(--auto-purple);
}

.TinyLight-GreyLeft {
    font-family: OpenSans, sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: var(--disabled);
}

.TinyLight-GreyCenter {
    font-family: OpenSans, sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: var(--disabled);
}

.TinyGreenLeft {
    font-family: OpenSans, sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: var(--hypo-protect);
}

.TinyGreenCenter {
    font-family: OpenSans, sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: var(--hypo-protect);
}

.TinyDark-GreyLeft {
    font-family: OpenSans, sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: var(--dark-grey);
}

.TinyDark-GreyCenter {
    font-family: OpenSans, sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: var(--dark-grey);
}

.TinyBlueLeft {
    font-family: OpenSans, sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: var(--manual-blue);
}

.TinyBlueCenter {
    font-family: OpenSans, sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: var(--manual-blue);
}

.TinyBlackLeft {
    font-family: OpenSans, sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: var(--black);
}

.TinyBlackCenter {
    font-family: OpenSans, sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: var(--black);
}

.TinyRightYellow {
    font-family: SFProText, sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: right;
    color: #ffa700;
}

.TinyRightWhite {
    font-family: SFProText, sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: right;
    color: var(--white);
}

.TinyRightRed {
    font-family: SFProText, sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: right;
    color: #f75e4c;
}

.TinyRightPurple {
    font-family: SFProText, sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: right;
    color: #743dbc;
}

.TinyRightLightGray {
    font-family: SFProText, sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: right;
    color: #8d8d93;
}

.TinyRightGray {
    font-family: SFProText, sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: right;
    color: #656565;
}

.TinyRightBlack {
    font-family: SFProText, sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: right;
    color: var(--black);
}

.TinyLeftYellow {
    font-family: SFProText, sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #ffa700;
}

.TinyLeftWhite {
    font-family: SFProText, sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: var(--white);
}

.TinyLeftRed {
    font-family: SFProText, sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #f75e4c;
}

.TinyLeftPurple {
    font-family: SFProText, sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #743dbc;
}

.TinyLeftLightGray {
    font-family: SFProText, sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #8d8d93;
}

.TinyLeftGray {
    font-family: SFProText, sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #656565;
}

.TinyLeftBlack {
    font-family: SFProText, sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: var(--black);
}

.TinyCenterYellow {
    font-family: SFProText, sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #ffa700;
}

.TinyCenterWhite {
    font-family: SFProText, sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: var(--white);
}

.TinyCenterRed {
    font-family: SFProText, sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #f75e4c;
}

.TinyCenterPurple {
    font-family: SFProText, sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #743dbc;
}

.TinyCenterLightGray {
    font-family: SFProText, sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #8d8d93;
}

.TinyCenterGray {
    font-family: SFProText, sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #656565;
}

.TinyCenterBlack {
    font-family: SFProText, sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: var(--black);
}

.Caption12-Bold1-Light1-Label-Color1-Left-Aligned {
    font-family: SFProText, sans-serif;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: var(--black);
}

.Tiny-RegularYellowLeft {
    font-family: OpenSans, sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: var(--high-bg);
}

.Tiny-RegularYellowCenter {
    font-family: OpenSans, sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: var(--high-bg);
}

.Tiny-RegularWhiteLeft {
    font-family: OpenSans, sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: var(--white);
}

.Tiny-RegularWhiteCenter {
    font-family: OpenSans, sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: var(--white);
}

.Tiny-RegularRed-Lef {
    font-family: OpenSans, sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: var(--low-bg);
}

.Tiny-RegularRed-Cen {
    font-family: OpenSans, sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: var(--low-bg);
}

.Tiny-RegularPurpleLeft {
    font-family: OpenSans, sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: var(--auto-purple);
}

.Tiny-RegularLight-GreyLeft {
    font-family: OpenSans, sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: var(--disabled);
}

.Tiny-RegularGreenLeft {
    font-family: OpenSans, sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: var(--hypo-protect);
}

.Tiny-RegularGreenCenter {
    font-family: OpenSans, sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: var(--hypo-protect);
}

.Tiny-RegularDark-GreyLeft {
    font-family: OpenSans, sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: var(--dark-grey);
}

.Tiny-RegularDark-GreyCenter {
    font-family: OpenSans, sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: var(--dark-grey);
}

.Tiny-RegularBlueLeft {
    font-family: OpenSans, sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: var(--manual-blue);
}

.Tiny-RegularBlueCenter {
    font-family: OpenSans, sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: var(--manual-blue);
}

.Tiny-RegularBlackLeft {
    font-family: OpenSans, sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: var(--black);
}

.Tiny-RegularBlackCenter {
    font-family: OpenSans, sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: var(--black);
}

.FootnoteRegularDefault2LeftJ {
    font-family: OpenSans, sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #808080;
}

.Tiny-RegularRightYellow {
    font-family: SFProText, sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: right;
    color: #ffa700;
}

.Tiny-RegularRightWhite {
    font-family: SFProText, sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: right;
    color: var(--white);
}

.Tiny-RegularRightRed {
    font-family: SFProText, sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: right;
    color: #f75e4c;
}

.Tiny-RegularRightPurple {
    font-family: SFProText, sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: right;
    color: #743dbc;
}

.Tiny-RegularRightLightGray {
    font-family: SFProText, sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: right;
    color: #8d8d93;
}

.Tiny-RegularRightGray {
    font-family: SFProText, sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: right;
    color: #656565;
}

.Tiny-RegularRightBlack {
    font-family: SFProText, sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: right;
    color: var(--black);
}

.Tiny-RegularLeftYellow {
    font-family: SFProText, sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #ffa700;
}

.Tiny-RegularLeftWhite {
    font-family: SFProText, sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: var(--white);
}

.Tiny-RegularLeftRed {
    font-family: SFProText, sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #f75e4c;
}

.Tiny-RegularLeftPurple {
    font-family: SFProText, sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #743dbc;
}

.Tiny-RegularLeftLightGray {
    font-family: SFProText, sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #8d8d93;
}

.Tiny-RegularLeftGray {
    font-family: SFProText, sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #656565;
}

.Tiny-RegularLeftBlack {
    font-family: SFProText, sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: var(--black);
}

.Tiny-RegularCenterYellow {
    font-family: SFProText, sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #ffa700;
}

.Tiny-RegularCenterWhite {
    font-family: SFProText, sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: var(--white);
}

.Tiny-RegularCenterRed {
    font-family: SFProText, sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #f75e4c;
}

.Tiny-RegularCenterPurple {
    font-family: SFProText, sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #743dbc;
}

.Tiny-RegularCenterLightGray {
    font-family: SFProText, sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #8d8d93;
}

.Tiny-RegularCenterGray {
    font-family: SFProText, sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #656565;
}

.Tiny-RegularCenterBlack {
    font-family: SFProText, sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: var(--black);
}

.Caption11-Default1-Light2-Secondary-Label-Color2-Center-Aligned {
    font-family: SFProText, sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: rgba(60, 60, 67, 0.6);
}

.Caption-11RegularLeft2-Secondary {
    font-family: SFProText, sans-serif;
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.45;
    letter-spacing: 0.07px;
    color: rgba(60, 60, 67, 0.6);
}

@font-face {
    font-family: Consolas, sans-serif;
    src: local(Consolas), url(/assets/fonts/Consolas.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
}