// TODO: tech debt - Move this to theme file
.MuiChip-root.Chip {
    color: var(--colorFg);
    text-transform: uppercase;
    font-size: 12px;
    padding: 4px 4px;
    border-radius: 30px;
    height: unset;
    font-family: inherit;
    margin: 8px 0;
    line-height: 16px;
    letter-spacing: .2px;

    span {
        padding-left: 10px;
        padding-right: 6px;
    }

    &.MuiChip-colorInfo {
        background-color: var(--info);
    }

    &.MuiChip-colorWarning {
        background: var(--warning);
    }
    
    &.MuiChip-colorPrimary {
        background: var(--light-purple);
    }
}
